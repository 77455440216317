<template>
  <v-container pa-0 fluid>
    <div v-if="!loading && product">
      <v-container class="text-center">
        <v-row no-gutters>            
          <v-col cols="12" md="8">
            <v-container class="text-left">
              <v-row>
                <v-col cols="2" class="mt-2">
                  <div v-for="(img, idx) in product.images"
                    :key="idx"
                    class="my-2">
                    <v-btn plain
                      :ripple="false"
                      :class="{ activeImg: isActive(img.url) }"
                      :width="$vuetify.breakpoint.xsOnly ? 47 : 92"
                      :height="$vuetify.breakpoint.xsOnly ? 37 : 62"
                      @click="changeImage(img.url)">
                      <v-img :src="img.url"
                        :width="$vuetify.breakpoint.xsOnly ? 45 : 90"
                        contain>
                      </v-img>
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="10">
                  <v-img class="rounded-lg"
                    :src="imagePath"
                    :lazy-src="imagePath"
                    aspect-ratio="3/2">
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" justify="center">
                        <v-progress-circular indeterminate color="secondary"></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <div class="body-2 primary--text my-2">
                    {{ 'сode' | localize }}: {{ product.id }}
                  </div>
                  <div class="subtitle-1 primary--text">{{ 'Description' | localize }}:</div>
                  <div class="text-justify">{{ localDesc(product) }}</div>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col cols="12" md="4">
              <v-container class="mx-8 text-center">
                <v-theme-provider dark>
                  <h2 class="title font-weight-semibold primary--text">{{localName(product)}}</h2>
                </v-theme-provider>
                
                <v-responsive
                  class="mx-auto mb-8"
                  width="56"
                >
                  <v-divider class="mb-1"></v-divider>
                  <v-divider></v-divider>
                </v-responsive>
                
                <div>{{ 'Quantity' | localize }}:</div>
                <v-responsive width="70" class="mx-auto">
                  <v-text-field dense 
                    type="number" min="1" 
                    outlined
                    v-model.number="quantity">
                  </v-text-field>
                </v-responsive>
                <div>
                  {{ 'Price' | localize }}: {{ getPrice(product).toLocaleString('en-US') }} {{ currency }}
                </div>
                <div>
                  <v-btn
                    class="mr-auto my-4 text-lowercase"
                    color="primary"
                    dark
                    @click="toCart()">
                    <v-icon>mdi-cart</v-icon>
                    {{ 'add to cart' | localize }}
                  </v-btn>
                </div>
                <div>
                  <v-btn class="mr-auto my-4 text-lowercase"
                    color="primary" outlined
                    to="/products">
                    {{'continue shopping' | localize }}
                  </v-btn>
                </div>
                <v-row justify="end" class="mt-12">
                  <v-card class="pa-4"
                    max-width="320">
                    <v-card-text class="text-left">
                      <v-row class="primary--text text-subtitle-2">
                        <v-icon color="primary" class="mr-2">mdi-truck</v-icon>
                        {{ 'Order delivery conditions' | localize }}:
                      </v-row>
                      <v-row class="text-subtitle-2">
                        ~ {{ 'in Chisinau' | localize }}:
                      </v-row>  
                      <v-row class="text-body-2">
                        {{ 'up to' | localize }} {{ delivery_free }} MDL - {{ delivery_chisinau }} MDL
                      </v-row>
                      <v-row class="text-body-2">
                        {{ 'more than' | localize }} {{ delivery_free }} MDL - 0 MDL
                      </v-row>   
                      <v-row class="text-subtitle-2">
                        ~ {{ 'outside Chisinau (within Moldova)' | localize }}:
                      </v-row>
                      <v-row class="text-body-2">
                        {{ 'postal delivery' | localize }} - {{  delivery_moldova }} MDL
                      </v-row>
                      <v-row class="text-subtitle-2">
                        ~ {{ 'delivery outside Moldova' | localize }}:
                      </v-row>
                      <v-row class="text-body-2">
                        {{ 'please call for details' | localize }}: +373 78 679 888
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-row>
              </v-container>
            </v-col>
        </v-row>
        </v-container>
        <div class="py-2"></div>
    </div>
    <div v-else>
      <loader />
    </div>
  </v-container>
</template>
<script>
  
import Loader from '../layouts/Loader.vue'

export default {
  name: 'ProductPage',
  data() {
    return {
      selected: 0,
      activeImage: null,
      imagePath: null,
      quantity: 1,
      /*delivery prices*/
      delivery_chisinau: 50,
      delivery_moldova: 70,
      delivery_free: 500,
    }
  },
  computed: {
    loading() {
      return this.$store.getters.loading;
    },
    product() {
      return this.$store.getters.product;
    },
    locale() {
      return this.$store.getters.locale;
    },
    currency() {
      return this.$store.getters.currency;
    },
    productsInCart() {
      return this.$store.getters.productsInCart;
    }
  },
  methods: {
    changeImage(src) {
      this.activeImage = src;
      src ? 
        this.imagePath = src:
        this.imagePath = 'https://firebasestorage.googleapis.com/v0/b/momykeep-f0708.appspot.com/o/products%2Fnot_found.png?alt=media&token=55870c07-b1b7-4e56-b1d9-e1145d1c9fa3';
    }, 
    isActive(item) {
      if (this.activeImage) {
        return Boolean(item === this.activeImage);
      }
      return false
    },
    localName(item) {
      return item[`name_${this.locale.split('-')[0]}`]
    },
    localDesc(item) {
      return item[`description_${this.locale.split('-')[0]}`]
    },
    getPrice(item) {
      if (this.currency === 'MDL') {
        return item.price;
      }
      const key = `price_${this.currency}`;
      return item[key];
    },
    async toCart() {
      
      const product = this.product;
      const inCart = this.productsInCart.find(prod => prod.id === product.id)
           
      if(inCart) {
        //update Product quantity in cart
        inCart.numbers+= this.quantity;
        
        try {
          await this.$store.dispatch('updateProduct', inCart);
        }
        catch (error) {
          await this.$store.dispatch('setError', error.message)
        }
        
      }
      else {
        
        this.$store.dispatch('addMessage'); 
        product.numbers = this.quantity;
        
        try {
          await this.$store.dispatch('addProductToCart', product);
          this.quantity = 1;
        }
        catch (error) {
          await this.$store.dispatch('setError', error.message)
        }
    
      } 
      //message to user
      this.$store.dispatch('showSnackbar', {
        on: true,
        color: 'white',
        text: 'added to your cart',
        timeout: 1500
      });
    },
  },
  async created() {
    const productId = this.$route.params.id
    if (productId) {
      try {
        await this.$store.dispatch('fetchProduct', productId);
      }
      catch (err) {
        console.log(err)
      }
    }

    if (this.product) {
      if (this.product.images && this.product.images.length) {
        this.activeImage = this.product.images[0].url;
      }
      else {
        this.activeImage = 'https://firebasestorage.googleapis.com/v0/b/momykeep-f0708.appspot.com/o/products%2Fnot_found.png?alt=media&token=55870c07-b1b7-4e56-b1d9-e1145d1c9fa3';
      }
      this.imagePath = this.activeImage;
    } 
  },
  components: { Loader }
}
</script>
<style>
.activeImg {
  border: 1px solid #64668A; 
}
.short {
    width: 45%;
  }
</style>
